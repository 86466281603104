<template>
  <div>
    <b-overlay :show="isFetchingConsultant" rounded="sm">
      <b-alert
        variant="danger"
        :show="
          (consultant && consultant.deleted) ||
            (consultant && !consultant.active)
        "
      >
        <h4 class="alert-heading">
          Deleted consultant
        </h4>
        <div class="alert-body">
          <span
            >This consultant has already been deleted / deactivated. You can use
            the available option(s) to restore / reactivate the account</span
          >
          <div class="text-left mt-1">
            <b-button
              @click="restoreConsultant"
              variant="dark"
              v-if="consultant && consultant.deleted && !consultant.active"
            >
              <feather-icon icon="RepeatIcon"></feather-icon>
              Restore Account</b-button
            >
            <b-button
              @click="reactivateConsultant"
              variant="dark"
              v-if="consultant && !consultant.deleted && !consultant.active"
            >
              <feather-icon icon="RefreshCcwIcon"></feather-icon>
              Reactivate
            </b-button>
          </div>
        </div>
      </b-alert>

      <b-row class="match-height">
        <b-col cols="12" md="12">
          <StatisticsCard
            :title="`ID: ${consultant._id}`"
            :lastUpdated="consultant.updatedAt"
            :data="userDetails"
            :deleted="consultant.deleted"
            :active="consultant.active"
            :isActionButtonsDisabled="isActionButtonsDisabled"
            @initEditConsultant="initEditConsultant"
            @deleteConsultant="deleteConsultant"
            @deactivate="deactivateConsultant"
            @reactivate="reactivateConsultant"
            @restore="restoreConsultant"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col cols="2">
          <PaymentCard
            :title="`₹${$commaNumber(totalAmountEarned)}`"
            subTitle="Earned"
            icon="DollarSignIcon"
            color="success"
            url="/payments"
          />
        </b-col>
        <b-col cols="2">
          <PaymentCard
            :title="`₹${$commaNumber(paidPayments)}`"
            subTitle="Paid"
            icon="DollarSignIcon"
            color="success"
            url="/payments?status=paid"
          />
        </b-col>
        <b-col cols="2">
          <PaymentCard
            :title="`₹${$commaNumber(pendingPayments)}`"
            subTitle="Pending"
            icon="DollarSignIcon"
            color="warning"
            url="/payments?status=pending"
          />
        </b-col>
        <b-col cols="2">
          <PaymentCard
            :title="`₹${$commaNumber(dueThisMonth)}`"
            subTitle="Due This Month"
            icon="DollarSignIcon"
            color="warning"
            url="/payments?due=this_month"
          />
        </b-col>
        <b-col cols="2">
          <PaymentCard
            :title="`₹${$commaNumber(paymentsOnHold)}`"
            subTitle="On Hold"
            icon="DollarSignIcon"
            color="danger"
            url="/payments?status=on-hold"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12">
          <b-card>
            <b-card-title>
              <div
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
              >
                <h4 class="card-title mb-0">Leads</h4>
                <div class="text-right  w-100 w-md-auto">
                  <b-form-group class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input
                        @change="checkEmptySearchInput"
                        id="filterInput"
                        v-model="search"
                        type="search"
                        placeholder="Type to Search"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="getLeads"
                          :disabled="!search"
                        >
                          Search
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </b-card-title>
            <b-table
              class="table-responsive"
              @row-clicked="onRowClicked"
              :busy="isTableBusy"
              :items="leads"
              hover
              :fields="fields"
              show-empty
            >
              <template #empty="scope">
                <div class="text-center">
                  <p>0 Leads found!</p>
                </div>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner variant="primary" class="align-middle"></b-spinner>
                  <strong class="ml-1">Loading leads...</strong>
                </div>
              </template>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(services)="data">
                <b-badge
                  class="mr-1"
                  variant="primary"
                  v-for="(service, index) in data.item.services"
                  :key="index"
                >
                  <feather-icon
                    v-if="service.dealClosed"
                    icon="ThumbsUpIcon"
                  ></feather-icon>
                  {{ service.name }}</b-badge
                >
              </template>
            </b-table>

            <b-card-footer>
              <b-row>
                <b-col cols="6"> </b-col>
                <b-col cols="6">
                  <b-pagination
                    class="justify-content-end"
                    v-model="page"
                    :per-page="limit"
                    :total-rows="totalLeads"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <CreateConsultantModal
      :isBusy="isModalBusy"
      :consultant="consultant"
      :show="showCreateConsultantModal"
      :action="action"
      @closeConsultantPopUp="closeConsultantPopUp"
      @updated="updatedConsultant"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BOverlay,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BCardFooter,
  BButton,
  BAlert,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTable,
  BPagination,
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import CreateConsultantModal from "@/components/consultant/CreateConsultantModal.vue";
import EarningsChart from "@/components/consultant/EarningsChart.vue";
import StatisticsCard from "@/components/consultant/StatisticsCard.vue";
import PaymentCard from "@/components/DashboardWidgets/PaymentCard.vue";

export default {
  components: {
    BCard,
    BCardTitle,
    BOverlay,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardFooter,
    BButton,
    CreateConsultantModal,
    EarningsChart,
    StatisticsCard,
    BAlert,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    BPagination,
    PaymentCard,
  },
  data() {
    return {
      consultant: {
        updatedBy: {},
        city: "",
        createdBy: {},
        name: "",
        sector: "",
        email: "",
        createdAt: "",
        updatedAt: "",
        _id: "",
        deleted: false,
        active: true,
        activated: true,
      },
      isFetchingConsultant: true,
      isFetchingLeads: true,
      isModalBusy: false,
      showCreateConsultantModal: false,
      action: "edit",
      series: [53, 16, 31],
      userDetails: [],

      // Table Data
      limit: 10,
      page: 1,
      search: "",
      citySelected: "",
      totalLeads: 0,
      leads: [],
      isTableBusy: false,
      fields: [
        { key: "index", label: "S.No" },
        "company",
        {
          key: "name",
          label: "Name",
          thStyle: { width: "150px" },
          tdClass: "text-nowrap",
        },
        {
          key: "email",
          label: "Email",
          thStyle: { width: "150px" },
          tdClass: "text-nowrap",
        },
        "phone",
        "services",
      ],

      //payment details
      pendingPayments: 0,
      paidPayments: 0,
      dueThisMonth: 0,
      paymentsOnHold: 0,
    };
  },
  computed: {
    isActionButtonsDisabled() {
      if (
        this.consultant &&
        (this.consultant.deleted || !this.consultant.active)
      ) {
        return true;
      }

      return false;
    },
    totalAmountEarned() {
      return (
        parseInt(this.pendingPayments) +
        parseInt(this.paidPayments) +
        parseInt(this.paymentsOnHold)
      );
    },
  },
  methods: {
    closeConsultantPopUp() {
      this.showCreateConsultantModal = false;
    },
    updateConsultant() {},
    formatData(consultant) {
      let data = [
        {
          icon: "UserIcon",
          color: "light-primary",
          title: consultant.name,
          subtitle: "Name",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "MailIcon",
          color: "light-info",
          title: consultant.email,
          subtitle: "Email",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "MapPinIcon",
          color: "light-danger",
          title: consultant.city,
          subtitle: "City",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "PieChartIcon",
          color: "light-success",
          title: consultant.sector,
          subtitle: "Sector",
          customClass: "",
        },
      ];

      return data;
    },
    getConsultant() {
      this.$set(this, "isFetchingConsultant", true);
      let consultantID = this.$route.params.consultant;
      let token = this.$getUserToken();
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/consultant/${consultantID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);

          this.$set(this, "consultant", response.data.consultant);
          this.$set(
            this,
            "userDetails",
            this.formatData(response.data.consultant)
          );
          let pendingPayments =
            response.data.paymentsPending &&
            response.data.paymentsPending.length
              ? response.data.paymentsPending[0].totalAmount
              : 0;
          let paidPayments =
            response.data.paymentsPaid && response.data.paymentsPaid.length
              ? response.data.paymentsPending[0].totalAmount
              : 0;
          let dueThisMonth =
            response.data.dueThisMonth && response.data.dueThisMonth.length
              ? response.data.dueThisMonth[0].totalAmount
              : 0;
          let paymentsOnHold =
            response.data.paymentsOnHold && response.data.paymentsOnHold.length
              ? response.data.paymentsOnHold[0].totalAmount
              : 0;
          this.$set(this, "paidPayments", paidPayments);
          this.$set(this, "pendingPayments", pendingPayments);
          this.$set(this, "dueThisMonth", dueThisMonth);
          this.$set(this, "paymentsOnHold", paymentsOnHold);
          this.$set(this, "isFetchingConsultant", false);
        })
        .catch((error) => {
          console.log(error.response);
          this.$set(this, "isFetchingConsultant", false);
        });
    },
    formatDate(timestamp) {
      if (!timestamp) {
        return "";
      }
      return moment(timestamp).format("MMMM Do YYYY,h:mm:ss a");
    },
    initEditConsultant() {
      this.$set(this, "showCreateConsultantModal", true);
    },
    updatedConsultant() {
      this.$set(this, "showCreateConsultantModal", false);
      this.getConsultant();
    },
    deleteConsultant() {
      let consultantID = this.consultant._id;
      const token = this.$getUserToken();
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/consultant/${consultantID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.getConsultant();
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    deactivateConsultant() {
      let consultantID = this.consultant._id;
      const token = this.$getUserToken();
      axios
        .put(
          `${process.env.VUE_APP_SERVER_URL}/consultant/${consultantID}/deactivate`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.getConsultant();
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    async reactivateConsultant() {
      let value = await this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to reactivate the consultant.",
        {
          title: "Please Confirm",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (value === true) {
        let consultantID = this.consultant._id;
        const token = this.$getUserToken();
        axios
          .put(
            `${process.env.VUE_APP_SERVER_URL}/consultant/${consultantID}/reactivate`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.getConsultant();
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    },
    async restoreConsultant() {
      let value = await this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to restore the consultant.",
        {
          title: "Please Confirm",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (value === true) {
        let consultantID = this.consultant._id;
        const token = this.$getUserToken();
        axios
          .put(
            `${process.env.VUE_APP_SERVER_URL}/consultant/${consultantID}/restore`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.getConsultant();
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    },
    onRowClicked(item) {
      this.$router.push(`/leads/${item._id}`);
    },
    getLeads() {
      let consultantID = this.$route.params.consultant;
      let token = this.$getUserToken();
      this.$set(this, "isFetchingLeads", true);
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/consultant/${consultantID}/leads?limit=${this.limit}&page=${this.page}&search=${this.search}&city=${this.citySelected}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.leads = response.data.leads;
          this.totalLeads = response.data.totalLeads;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    resetPagination() {
      this.$set(this, "page", 1);
      this.$set(this, "search", "");
    },
    checkEmptySearchInput(value) {
      if (!value) {
        this.resetPagination();
        this.getLeads();
      }
    },
  },
  mounted() {
    this.getConsultant();
    this.getLeads();
  },
};
</script>

<template>
  <router-link :to="url">
    <b-card class="text-center">
      <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
        <feather-icon size="21" :icon="icon" />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ title }}
        </h2>
        <span class="text-secondary">{{ subTitle }}</span>
      </div>
    </b-card>
  </router-link>
</template>

<script>
import { BCard, BAvatar, BButton, VBPopover, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
    BCardText,
  },
  directives: {
    "b-popover": VBPopover,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: [Number, String],
      required: true,
    },
    subTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<template>
  <b-card v-if="data && data.length" no-body class="card-statistics">
    <b-card-header>
      <!-- <b-card-title class="font-small-3">{{ title }}</b-card-title> -->
      <b-card-text class="font-small-2 mr-25 mb-0 ml-auto">
        <div class="d-flex align-items-center">
          Last updated {{ $formatDate(lastUpdated, "DD-MM-YYYY hh:mm a") }}

          <div class="text-right">
            <b-button
              v-b-tooltip.hover.top="'Edit Consultant'"
              :disabled="isActionButtonsDisabled"
              @click="initEditConsultant"
              size="sm"
              variant="outline-primary"
              class="btn-icon ml-1"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="'Delete Consultant'"
              v-if="!deleted && !active"
              size="sm"
              @click="deleteConsultant"
              variant="outline-danger"
              class="btn-icon ml-1"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="'Deactivate Consultant'"
              v-if="!deleted && active"
              size="sm"
              @click="deactivateConsultant"
              variant="outline-danger"
              class="btn-icon ml-1"
            >
              <feather-icon icon="PowerIcon" />
            </b-button>
          </div>
        </div>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0 text-break">
                {{ item.title }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    title: {
      type: String,
    },
    data: {
      type: Array,
      default: () => [],
    },
    isActionButtonsDisabled: {
      type: Boolean,
      default: true,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
    lastUpdated: {
      type: String,
      required: true,
    },
  },
  methods: {
    initEditConsultant() {
      this.$emit("initEditConsultant", true);
    },
    deleteConsultant() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete the consultant.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-primary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$emit("deleteConsultant", true);
          }
        });
    },
    deactivateConsultant() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to deactivate the consultant.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-primary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$emit("deactivate", true);
          }
        });
    },
  },
};
</script>
